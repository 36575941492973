import { PublicKey } from "@solana/web3.js";
import isEmpty from "lodash/isEmpty";

declare global {
  interface Window {
    token: string;
    // ethereum: any;
    solana: any;
    coin98: any;
    gatewallet: {
      solana: {
        publicKey?: PublicKey;
        isConnected: boolean;
        signMessage: (message: any, type: string) => any;
        connect: () => any;
        getAccount: () => any;
        gateAccountInfo: () => any;
        onAccountChange: () => any;
        signAndSendTransaction: (transaction: any) => any;
        signTransaction: (transaction: any) => any;
        signAllTransactions: (transaction: any) => any;
        on: any;
        off: any;
      };
    };
    // web3: Web3;
  }
}

interface IENVS {
  REACT_APP_APP_ENDPOINT: string;
  REACT_APP_SOL_RPC: string;
  REACT_APP_USDT_TOKEN: string;
  REACT_APP_USDC_TOKEN: string;
  REACT_APP_FEE_BASIS_POINTS: string;
  REACT_APP_MAX_FEE: string;
  REACT_APP_API_ENDPOINT: string;
  REACT_APP_API_NFT_ENDPOINT: string;
  REACT_APP_WEB3_AUTH_CHAIN_ID: string;
  REACT_APP_WEB3_AUTH_CLIENT_ID: string;
  REACT_APP_WEB3_AUTH_NETWORK: string;
  // mint
  REACT_APP_MINT_CREATOR: string;
  REACT_APP_MINT_CANDY_MACHINE: string;
  REACT_APP_MINT_MASTER_PUBKEY: string;
}

const defaultEnvs: IENVS = {
  REACT_APP_APP_ENDPOINT: "https://play.manekopet.xyz",
  REACT_APP_SOL_RPC:
    "https://cool-greatest-needle.solana-devnet.quiknode.pro/56d900682d4a6267aaec5c705a4890ac13e0318e/",
  REACT_APP_USDT_TOKEN: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
  REACT_APP_USDC_TOKEN: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  REACT_APP_FEE_BASIS_POINTS: "500",
  REACT_APP_MAX_FEE: "100000",
  REACT_APP_API_ENDPOINT: "https://dev-api.manekopet.xyz",
  REACT_APP_WEB3_AUTH_CLIENT_ID:
    "BKdeNiYLdgsUIAE7DikQJmB-zf9DMqrhqL_o5OXMd3ydNANvvcxlj9Dg1Je7Etja51WYX3ZbUUS0LAEOudbBFBU",
  REACT_APP_WEB3_AUTH_CHAIN_ID: "0x3",
  REACT_APP_WEB3_AUTH_NETWORK: "sapphire_devnet",

  // mint
  REACT_APP_MINT_CREATOR: "tjWzLMrKqgN9gx45rdyUhXKqG9Ls6mYbGp2hLP63Xv1",
  REACT_APP_MINT_CANDY_MACHINE: "EaGNPBvTCHaaG64AKQNQJ4bUzGpEYYt2PfQRMDevRBBt",
  REACT_APP_MINT_MASTER_PUBKEY: "tjWzLMrKqgN9gx45rdyUhXKqG9Ls6mYbGp2hLP63Xv1",
  REACT_APP_API_NFT_ENDPOINT: "",
};

export const getEnvs = () => {
  let envs: any = {};
  try {
    const PROCCESS_ENV = process.env;
    if (!isEmpty(PROCCESS_ENV)) {
      Object.keys(PROCCESS_ENV).forEach((key: string) => {
        const value = PROCCESS_ENV[key];
        if (value === "true" || value === "false") {
          envs[key] = value === "true";
        } else {
          envs[key] = PROCCESS_ENV[key];
        }
        return key;
      });
    }
  } catch (error) {
    console.debug(error);
  } finally {
    envs = isEmpty(envs) ? defaultEnvs : envs;
  }
  return { ...envs, REACT_APP_DOMAIN_URL: window.location.origin };
};

export const ENVS: IENVS = getEnvs();
