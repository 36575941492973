import { ethers } from "ethers";
import { getChainParams } from "./getChainParams";

// Define the target chain

// Connect wallet and switch to target chain if necessary
export const connectWallet = async () => {
  if (!window.ethereum) {
    alert(
      "Metamask is not installed. Please install Metamask to use this app."
    );
    return;
  }
  const chain = getChainParams("11155111");

  try {
    // Request account access
    const provider = new ethers.providers.Web3Provider(window.ethereum as any);
    await window.ethereum.request({ method: "eth_requestAccounts" });

    // Get the current chain ID
    const currentChainId = await provider
      .getNetwork()
      .then((network) => network.chainId);

    // Check if the user is already on the target chain
    if (currentChainId !== parseInt(chain.chainId, 10)) {
      console.log(
        `Current chain ID: ${currentChainId}, switching to ${chain.chainId}`
      );
      await switchToTargetChain();
    } else {
      console.log(`Already connected to chain ${chain.chainId}`);
    }

    // Get user account
    const signer = provider.getSigner();
    const account = await signer.getAddress();

    return { provider, account };
  } catch (error: any) {
    console.error("Failed to connect wallet:", error.message);
  }
};

// Switch to the target chain
const switchToTargetChain = async () => {
  const chain = getChainParams("11155111");
  try {
    if (!window.ethereum) return;
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chain.chainId }],
    });
    console.log("Switched to target chain:", chain.chainName);
  } catch (error: any) {
    // If chain is not added to Metamask, add it
    if (error.code === 4902) {
      console.log("Target chain not found. Adding chain to Metamask...");
      await addTargetChain();
    } else {
      console.error("Failed to switch chain:", error.message);
    }
  }
};

// Add the target chain to Metamask
const addTargetChain = async () => {
  const chain = getChainParams("11155111");
  try {
    if (!window.ethereum) return;
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [chain],
    });
    console.log("Added and switched to target chain:", chain.chainName);
  } catch (error: any) {
    console.error("Failed to add target chain:", error.message);
  }
};

// Get account balance
export const getAccountBalance = async (
  account: string,
  provider: ethers.providers.Provider
) => {
  try {
    // Fetch the balance in wei (smallest unit of Ether)
    const balance = await provider.getBalance(account);

    // Convert balance to Ether
    const balanceInEther = ethers.utils.formatEther(balance);
    console.log(`Balance of ${account}: ${balanceInEther} ETH`);
    return balanceInEther;
  } catch (error) {
    console.error("Failed to fetch balance:", error);
    throw error;
  }
};
