import { MetaMaskProvider } from "@metamask/sdk-react";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FirstLoading from "./components/FirstLoading";
import Loader from "./components/Loader";
import { ENVS } from "./configs/Configs.env";
import { MetamaskWrapProvider } from "./providers/MetamaskWrapProvider";
import { Web3AuthProvider } from "./providers/Web3AuthProvider";
import Routes from "./routes";
const App = () => {
  console.log("EVN: ", ENVS);
  return (
    <React.Suspense fallback={<Loader />}>
      <MetaMaskProvider
        sdkOptions={{
          dappMetadata: {
            name: "Example React Dapp",
            url: window.location.href,
          },
          // infuraAPIKey: process.env.INFURA_API_KEY,
          // Other options.
        }}
      >
        <MetamaskWrapProvider>
          <Web3AuthProvider>
            <FirstLoading />
            <ToastContainer />
            <Routes />
          </Web3AuthProvider>
        </MetamaskWrapProvider>
      </MetaMaskProvider>
    </React.Suspense>
  );
};
export default App;
