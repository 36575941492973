import { NFTDetail } from "@/types/apis/nft";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export interface IUser {
  frens: number;
  id: number;
  incomeEarned: number;
  invitedBy: string;
  publicAddress: string;
  publicNFTAddress: string;
  publicTokenAddress: string;
  referralCode: string;
}

export interface IBalanceProfile {
  Address: string;
  balance: number;
  id: number;
  interest: number;
  itemId: number;
  name: string;
  status: number;
  symbol: string;
}
export interface UserActions {
  getData: (user: ProfileState) => void;
}

export interface ProfileState {
  user: IUser;
  balance: IBalanceProfile[];
  accessToken: string;
  nft: NFTDetail;
  userCVProfile: UserCVProfile;
}

export interface UserCVProfile {
  id: number;
  userId: number;
  fullName: string;
  email: string;
  phone: string;
  profileImage: string;
  schoolName: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  aboutMe: string;
  skillNormal: string;
  skillAdvance: string;
  languages: string;
  job?: Job;
  education?: Education;
}

export interface Job {
  ID: number;
  userProfileId: number;
  TitleJob: string;
  CompanyName: string;
  IsCurrentJob: number;
  Description: string;
  StartTime: string;
  EndTime: string;
}

export interface Education {
  ID: number;
  userProfileId: number;
  FieldStudy: string;
  SchoolName: string;
  StartTime: string;
  EndTime: string;
}

export type TUserStore = ProfileState & UserActions;

const defaultUser = {
  id: -1,
  publicAddress: "",
  publicTokenAddress: "",
  publicNFTAddress: "",
  referralCode: "",
  invitedBy: "",
  incomeEarned: 0,
  frens: 0,
};

const defaultUserCVProfile = {
  aboutMe: "",
  email: "",
  facebook: "",
  fullName: "",
  id: -1,
  languages: "",
  linkedin: "",
  phone: "",
  profileImage: "",
  schoolName: "",
  skillAdvance: "",
  skillNormal: "",
  twitter: "",
  userId: -1,
};

const defaultNft = {
  id: -1,
  userId: 0,
  class: "",
  level: 0,
  image: "",
  Score: 0,
  Status: 0,
};

const handleGetData = (draft: TUserStore, data: ProfileState) => {
  draft.user = data.user;
  draft.accessToken = data.accessToken;
  draft.balance = data.balance;
  draft.nft = data.nft;
  draft.userCVProfile = data.userCVProfile;
};

const profileNotAuthStore = create(
  persist(
    immer<TUserStore>(set => ({
      user: { ...defaultUser },
      balance: [],
      accessToken: "",
      nft: defaultNft,
      userCVProfile: defaultUserCVProfile,
      getData: (data: ProfileState) => set(draft => handleGetData(draft, data)),
    })),
    {
      name: "profile-notauth-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useGetDataNotAuth = () =>
  profileNotAuthStore(state => state.getData);
export const useProfileNotAuth = () =>
  profileNotAuthStore(state => ({
    user: state.user,
    balance: state.balance,
    accessToken: state.accessToken,
    nft: state.nft,
    userCVProfile: state.userCVProfile,
  }));
