export const getChainParams = (chainId: string) => {
  const chainParams: {
    [key: string]: {
      chainId: string;
      chainName: string;
      nativeCurrency: { name: string; symbol: string; decimals: number };
      rpcUrls: string[];
      blockExplorerUrls: string[];
    };
  } = {
    "1": {
      chainId: "0x1",
      chainName: "Ethereum Mainnet",
      nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
      rpcUrls: ["https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID"],
      blockExplorerUrls: ["https://etherscan.io"],
    },
    "137": {
      chainId: "0x89",
      chainName: "Polygon Mainnet",
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
      rpcUrls: ["https://polygon-rpc.com"],
      blockExplorerUrls: ["https://polygonscan.com"],
    },
    "56": {
      chainId: "0x38",
      chainName: "Binance Smart Chain Mainnet",
      nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
      rpcUrls: ["https://bsc-dataseed.binance.org"],
      blockExplorerUrls: ["https://bscscan.com"],
    },
    "11155111": {
      chainId: "0xaa36a7",
      chainName: "Sepolia",
      nativeCurrency: { name: "Sepolia", symbol: "SepoliaETH", decimals: 18 },
      rpcUrls: ["sepolia.infura.io"],
      blockExplorerUrls: ["sepolia.etherscan.io"],
    },
  };

  return chainParams[chainId];
};
