import { axiosPrivate } from "@/http/axiosPrivate";
import { axiosPublic } from "@/http/axiosPublic";
import { AuthLoginResponse } from "@/types/apis/auth";
import { AuthStateBalance, SocialData } from "@/types/auth";
import { User } from "@/types/user";

export const login = async (
  publicAddress: string,
  signedString: string,
  nonce: string,
  infoUser: any
): Promise<{ token: string; user: User }> => {
  const data = {
    publicAddress: publicAddress,
    signedString: signedString,
    nonce: nonce,
    socialdata: infoUser,
  };
  const res = await axiosPublic.post<AuthLoginResponse>(`/user/auth`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  return res.data;
};

export const requestNonce = async (
  publicAddress: string
): Promise<{ nonce: string; publicAddress: string; signed: number }> => {
  const data = {
    publicAddress: publicAddress,
  };
  const res = await axiosPublic.post(`/nonce`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (res.status !== 200) {
    throw new Error("bad request");
  }

  return res.data;
};

export const updateReferralProfile = async (
  publicAddress: any,
  invitedBy: any
): Promise<{ message: string; rewardIndex: number }> => {
  const data = {
    publicAddress: publicAddress,
    invitedBy: invitedBy,
  };

  const res: any = await axiosPrivate.post<any>(
    `/user/profile/update-referral`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res?.data;
};

export const getProfile = async (): Promise<{
  balance: AuthStateBalance;
  socialdata: SocialData;
  user: User;
}> => {
  const res: any = await axiosPrivate.get<any>(`/user/profile`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res?.data;
};
export const getCallbackSocial = ({ search }: { search: string }) => {
  const apiUrl = `/auth/social-callback${search}`;

  return axiosPrivate.get(apiUrl).then((res) => res);
};

export const uploadAvatar = async (file: any): Promise<any> => {
  const formData = new FormData();
  formData.append("avatar", file, "canvas.png");
  const res: any = await axiosPrivate.post<any>(
    `/user/profile/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return res?.data;
};

// Call API profile User not Login
export const getProfileUserNotAuth = async (address: string): Promise<any> => {
  const res: any = await axiosPrivate.get<any>(`/profile/${address}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res?.data;
};

export const updateInfo = async (data: any): Promise<any> => {
  const res: any = await axiosPrivate.post<any>(`/user/profile/info`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return res?.data;
};

export const updateEducation = async (data: any): Promise<any> => {
  const res: any = await axiosPrivate.post<any>(
    `/user/profile/update/education`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res?.data;
};

export const updateJob = async (data: any): Promise<any> => {
  const res: any = await axiosPrivate.post<any>(
    `/user/profile/update/job`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res?.data;
};

export const updateLanguages = async (data: any): Promise<any> => {
  const res: any = await axiosPrivate.post<any>(
    `/user/profile/update/languages`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res?.data;
};

export const updateSkill = async (data: any): Promise<any> => {
  const res: any = await axiosPrivate.post<any>(
    `/user/profile/update/skill`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res?.data;
};

export const updateEVMAddress = async (address: string) => {
  const res: any = await axiosPrivate.post<any>(
    `/user/profile/update/evmaddress`,
    { evmAddress: address },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res?.data;
};

export const getListNFTProfile = async (
  address: string,
  chain: string,
  category: string,
  sort: string,
  limit: number,
  offset: number
): Promise<any> => {
  const res: any = await axiosPublic.get<any>(
    `/profile/${address}/nfts/${chain}/${category}/${sort}/${limit}/${offset}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return res.data;
};
