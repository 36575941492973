import { updateEVMAddress } from "@/api/auth.service";
import useFetchProfile from "@/hooks/useFetchProfile";
import { getAccountBalance } from "@/lib/metamask";
import { useProfile } from "@/store/profileStore";
import { useSDK } from "@metamask/sdk-react";
import { ethers } from "ethers";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

// Define types for the state
type MetamaskContextType = {
  account: string | null;
  balance: string;
  provider: ethers.providers.Web3Provider | null;
  connectWallet: () => Promise<void>;
  disconnectWallet: () => void;
};

// Create the context
const MetamaskContext = createContext<MetamaskContextType | undefined>(
  undefined
);

export const useMetamask = () => {
  const context = useContext(MetamaskContext);
  if (!context) {
    throw new Error("context must be used within");
  }
  return context;
};

export const MetamaskWrapProvider = ({ children }: { children: ReactNode }) => {
  const [account, setAccount] = useState<string | null>(null);
  const [balance, setBalance] = useState<string>("");
  const { getMe } = useFetchProfile();
  const [provider, setProvider] =
    useState<ethers.providers.Web3Provider | null>(null);
  const { connected, sdk } = useSDK();
  const { user } = useProfile();
  // Connect to Metamask
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3Provider = new ethers.providers.Web3Provider(
          window.ethereum as any
        );
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const signer = web3Provider.getSigner();
        const userAccount = await signer.getAddress();
        setProvider(web3Provider);
        setAccount(userAccount);
      } catch (error) {
        console.error("Failed to connect wallet:", error);
      }
    } else {
      alert("Please install Metamask!");
    }
  };

  useEffect(() => {
    const handleConnectMetamask = async () => {
      if (account) return;
      try {
        const accounts = await (window.ethereum as any).request({
          method: "eth_accounts",
        });
        console.log("accounts: ", accounts);
        if (accounts && accounts.length) {
          setAccount(accounts[0]);
          const provider = new ethers.providers.Web3Provider(
            window.ethereum as any
          );
          const userBalance = await getAccountBalance(accounts[0], provider);
          setBalance(userBalance);
        }
      } catch (error) {
        console.log("🚀 ~ handleConnectMetamask ~ error:", error);
      }
    };
    if (connected) {
      handleConnectMetamask();
    }
  }, [connected]);

  useEffect(() => {
    const updateUser = async () => {
      try {
        if (!account) return;
        await updateEVMAddress(account);
        await getMe();
      } catch (error) {
        console.log("🚀 ~ updateUser ~ error:", error);
      }
    };
    if (user.publicAddress && account) {
      updateUser();
    }
  }, [user.publicAddress, account]);

  // Disconnect wallet
  const disconnectWallet = () => {
    setAccount(null);
    setProvider(null);
  };

  // Automatically detect account changes
  useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("🚀 ~ handleAccountsChanged ~ accounts:", accounts);
        if (accounts.length > 0) {
          setAccount(accounts[0]);
        } else {
          disconnectWallet();
        }
      };

      window.ethereum.on("accountsChanged", handleAccountsChanged as any);
      return () => {
        if (window.ethereum) {
          window.ethereum.removeListener(
            "accountsChanged",
            handleAccountsChanged
          );
        }
      };
    }
  }, []);

  return (
    <MetamaskContext.Provider
      value={{ account, provider, balance, connectWallet, disconnectWallet }}
    >
      {children}
    </MetamaskContext.Provider>
  );
};
